import { useQueryClient } from '@tanstack/react-query';
import { useState, type MouseEvent } from 'react';

import clsx from 'clsx';
import { useLocale } from 'next-intl';

import FavoriteSVG from '@/icons/FavoriteSVG';
import FilledHeartSVG from '@/icons/FilledHeartSVG';
import type { Locale } from '@/types/common.types';

import { useSearchParamsResult } from '@/hooks/useSearchParamsResult';
import {
  useToggleDislikeMutation,
  useToggleLikeMutation,
} from '@/views/OnlineTvView/useTvFavorite';

import { OnlineTVQueryKeys } from '@/contexts/QueryContext/query.keys';

import styles from '@/views/HomeView/OnlineTvList/FavoriteToggleButton.module.scss';
import { favoriteOption } from '@/views/OnlineTvView/config';

// ===============================================================

export interface FavoriteButtonProps {
  isFavorite: boolean;
  id: number;
  onSuccessLiked?: () => void;
}

// ===============================================================

export const FavoriteToggleButton = (props: FavoriteButtonProps) => {
  const { isFavorite, id, onSuccessLiked } = props;
  const [isLoading, setIsLoading] = useState(false);

  const queryClient = useQueryClient();
  const locale = useLocale() as Locale;

  const { queryParams } = useSearchParamsResult();
  const { categoryId } = queryParams as { categoryId: string };

  const onSuccessAfterLiked = onSuccessLiked
    ? onSuccessLiked
    : () => {
        const channelsCasheKey = OnlineTVQueryKeys.channels('favourite', locale);
        const categoriesCasheKey = OnlineTVQueryKeys.category(locale);
        const selectedCasheKey = OnlineTVQueryKeys.channels(categoryId, locale);

        queryClient.invalidateQueries({
          queryKey: channelsCasheKey,
        });

        queryClient.invalidateQueries({
          queryKey: categoriesCasheKey,
        });

        queryClient.invalidateQueries({
          queryKey: channelsCasheKey,
        });

        if (categoryId !== favoriteOption.value?.toString())
          queryClient.invalidateQueries({
            queryKey: selectedCasheKey,
          });
      };

  const { isPending: isLikePending, mutate: likeMutate } = useToggleLikeMutation({
    onSuccess: onSuccessAfterLiked,
    onSettled: () => setTimeout(() => setIsLoading(false), 2000),
  });

  const { isPending: isDislikePending, mutate: dislikeMutate } = useToggleDislikeMutation({
    onSuccess: onSuccessAfterLiked,
    onSettled: () => setTimeout(() => setIsLoading(false), 2000),
  });

  const handleFavoriteButtonClick = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (isLoading) return;

    setIsLoading(true);

    if (isFavorite) {
      dislikeMutate(id);
    } else {
      likeMutate(id);
    }
  };

  const isVisibleUnFavorite = isFavorite;
  const isVisibleFavorite = !isFavorite;

  return (
    <div
      role="button"
      tabIndex={-1}
      onClick={handleFavoriteButtonClick}
      className={clsx(styles.tvFavoriteButton, {
        [styles.active]: isFavorite,
        [styles.disabled]: isDislikePending || isLikePending,
      })}
      aria-label={isFavorite ? 'Удалить из избранного' : 'Добавить в избранное'}
      title={isFavorite ? 'Удалить из избранного' : 'Добавить в избранное'}
    >
      {isVisibleFavorite && <FavoriteSVG width={25} height={25} />}
      {isVisibleUnFavorite && <FilledHeartSVG width={25} height={25} />}
    </div>
  );
};
