import { useMutation, UseMutationOptions } from '@tanstack/react-query';

import { OnlineTvApi } from '@/api/domains/online-tv.api';

export const useToggleLikeMutation = ({
  onSuccess,
  onSettled,
}: Pick<UseMutationOptions<unknown, unknown, number>, 'onSuccess' | 'onSettled'>) =>
  useMutation({
    mutationFn: (channelId: number) => OnlineTvApi.toggleLike({ channelId }),
    onSuccess,
    onSettled,
  });

export const useToggleDislikeMutation = ({
  onSuccess,
  onSettled,
}: Pick<UseMutationOptions<unknown, unknown, number>, 'onSuccess' | 'onSettled'>) =>
  useMutation({
    mutationFn: (channelId: number) => OnlineTvApi.toggleDislike({ channelId }),
    onSuccess,
    onSettled,
  });
