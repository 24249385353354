import { useQuery } from '@tanstack/react-query';

import { useLocale } from 'next-intl';
import { useSearchParams } from 'next/navigation';

import { Locale } from '@/types/common.types';

import { useVerification } from '@/contexts/VerificationContext/useVerification';
import { useSearchParamsResult } from '@/hooks/useSearchParamsResult';

import { OnlineTvApi } from '@/api/domains/online-tv.api';

import { OnlineTVQueryKeys } from '@/contexts/QueryContext/query.keys';

export const useTvCategories = () => {
  const locale = useLocale() as Locale;

  const { isInitialCheckDone } = useVerification();

  const activeChannelId = useSearchParams().get('channelId');

  const { queryParams } = useSearchParamsResult();

  const { categoryId: persistedCategoryId } = queryParams;

  const { data: categories = [], isPending } = useQuery({
    queryKey: OnlineTVQueryKeys.category(locale),
    queryFn: OnlineTvApi.fetchCategories,
    staleTime: Infinity,
    enabled: isInitialCheckDone,
  });

  const defaultCategoryId = categories[0]?.value;
  const activeCategoryId = persistedCategoryId || defaultCategoryId;
  const activeCategory = categories.find(
    option => option.value?.toString() === activeCategoryId?.toString(),
  );

  return {
    categories,
    isPending,
    defaultCategoryId,
    activeCategory,
    persistedCategoryId: persistedCategoryId?.toString() || defaultCategoryId?.toString(),
    activeChannelId: activeChannelId ? Number(activeChannelId) : null,
  };
};
