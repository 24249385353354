'use client';

import clsx from 'clsx';
import { useTranslations } from 'next-intl';
import { useSearchParams } from 'next/navigation';

import { breakpoints } from '@/app.config';
import LockSVG from '@/icons/LockSVG';
import { useRouter, usePathname } from '@/navigation';

import { useVerification } from '@/contexts/VerificationContext/useVerification';
import { useSearchParamsResult } from '@/hooks/useSearchParamsResult';
import { useTvCategories } from '@/views/OnlineTvView/useTvCategories';

import type { OnlineTvList } from '@/api/types/home.types';

import { Badge } from '@/components/Badge';
import { Image } from '@/components/Image';

import {
  FavoriteButtonProps,
  FavoriteToggleButton,
} from '@/views/HomeView/OnlineTvList/FavoriteToggleButton';
import styles from '@/views/HomeView/OnlineTvList/OnlineTvCard.module.scss';

// =================================================================

interface OnlineTvCardProps extends Pick<FavoriteButtonProps, 'onSuccessLiked'> {
  channel: OnlineTvList;
  isAuthenticated: boolean;
  isPlayed?: boolean;
}

// =================================================================

export const OnlineTvCard = (props: OnlineTvCardProps) => {
  const { channel, isAuthenticated, isPlayed, onSuccessLiked } = props;
  const { isFree, isHd, channelIcon, title, id, moduleId, isFavourite } = channel;
  const { defaultCategoryId } = useTvCategories();

  const router = useRouter();
  const pathname = usePathname();

  const { addQueryParams } = useSearchParamsResult();
  const t = useTranslations('verification');
  const categoryId = useSearchParams().get('categoryId') || defaultCategoryId;

  const {
    checkAuthVerification,
    checkSubscriptionVerification,
    setSubscriptionTitle,
    closeVerificationModal,
  } = useVerification();

  const checkAuth = checkAuthVerification({
    onSuccess: () => {
      if (isFree) {
        if (pathname === '/online-tv/player') {
          setTimeout(() => {
            addQueryParams({
              categoryId,
              autoplay: 'true',
              channelId: id,
            });
          });
        } else {
          setTimeout(() =>
            router.push(
              `/online-tv/player/?categoryId=${categoryId}&autoplay=true&channelId=${id}`,
            ),
          );
        }
        closeVerificationModal();
      } else {
        checkSubscriptionVerification({
          onMountFn: () => {
            // TODO : this title should be sent by the backend
            const subscriptionTitle =
              moduleId === 32
                ? t('subscriptionTitleSport', { channel: title })
                : t('subscriptionTitle', { channel: title });
            setSubscriptionTitle(subscriptionTitle);
            // ----------------------------------------------------------------

            router.push(`${pathname}?moduleId=${moduleId}`);
          },
          onSuccess: () => {
            router.push(`/online-tv/player/?autoplay=true&channelId=${id}`);
          },
        });
      }
    },
  });

  const handleChannelCardClick = () => {
    if (isPlayed) return;

    checkAuth();
  };

  return (
    <button
      id={String(channel.id) + 'channel'}
      type="button"
      onClick={handleChannelCardClick}
      className={clsx({
        [styles.onlineTvCard]: true,
        [styles.isPlayed]: props.isPlayed,
      })}
    >
      <div className={styles.cardInner}>
        {!isFree && isAuthenticated && (
          <span className={styles.lockIcon}>
            <LockSVG />
          </span>
        )}
        {isAuthenticated && (
          <FavoriteToggleButton id={id} isFavorite={isFavourite} onSuccessLiked={onSuccessLiked} />
        )}
        <Image
          aspectRatio={1 / 1}
          src={channelIcon}
          alt={title}
          fill
          sizes={`33vw, ${breakpoints.small} 25vw, ${breakpoints.medium} 17vw, ${breakpoints.large} 12.5vw`}
        />
        {isHd && (
          <div className={styles.badgeContainer}>
            <Badge position="default" variant="quality-tv">
              HD
            </Badge>
          </div>
        )}
        {isPlayed && (
          <div className={styles.playAnimation}>
            <div className={styles.line} />
            <div className={styles.line} />
            <div className={styles.line} />
          </div>
        )}
      </div>
    </button>
  );
};
